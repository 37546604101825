import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import marina from "../../Assets/Projects/marinaclinic.png";
import discoversharm from "../../Assets/Projects/discoversharm.png";
import surgipharma from "../../Assets/Projects/surgipharma.png";
import surgiexpo from "../../Assets/Projects/surgiexpo.png";
import freshcart from "../../Assets/Projects/freshcart.png";
import gamereview from "../../Assets/Projects/gamereview.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={surgiexpo}
              isBlog={false}
              title="Surgi Expo"
              description="The surgi Expo website is native with js, html, css, bootstrap for show the company benefits and its work with the export products."
              ghLink="https://github.com/NAFADYY/SURGI-EXPO"
              demoLink="https://nafadyy.github.io/SURGI-EXPO/index.html"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gamereview}
              isBlog={false}
              title="Games Reviews"
              description="You can explore the world of games with this website and know what are humans says about it with the download link."
              ghLink="https://github.com/NAFADYY/Discover-Games-World"
              demoLink="https://nafadyy.github.io/Discover-Games-World/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={surgipharma}
              isBlog={false}
              title="Surgi Pharma"
              description="The surgi Pharma website that allow the users to become a distributor with the company to get the machines and know the company."
              ghLink="https://github.com/NAFADYY?tab=repositories"
              demoLink="https://www.surgi-pharma.com"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={marina}
              isBlog={false}
              title="Marina Clinic"
              description="Marina Website is buld to display the services of the clinic and the doctors on it user can explore everythings on the clinic on this website like the devices that used and the sessions information."
              ghLink="https://github.com/NAFADYY?tab=repositories"
              demoLink="https://marina-clinics.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={freshcart}
              isBlog={false}
              title="Fresh Cart"
              description="Online Store that display product from an api and didnt allow the user to enter before register or login this website contain an online payment."
              ghLink="https://github.com/NAFADYY/FreshCart"
              demoLink="https://nafadyy.github.io/FreshCart/login"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={discoversharm}
              isBlog={false}
              title="Discover Sharm"
              description="To discover all parts of sharm you can book a trip with discover sharm company and they will take you fron your hotel you can book this trip online with your bank card."
              ghLink="https://github.com/NAFADYY/"
             demoLink="https://WWW.DISCOVERSHARM.COM"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
